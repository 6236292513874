<template>
	<div class="topContainer">
		<h1 class="sectionHeader">Payment Plan</h1>
		<div class="background">
			<div class="container">
				<div class="panel pricing-table">
					<div class="pricing-plan">
						<!--h2 class="pricing-header">runxact</h2-->
						<span class="pricing-price">{{ pricing[0] }}</span>
						<span class="pricing-price">or</span>
						<span class="pricing-price">{{ pricing[1] }}</span>
						<br />
						<div>
							<li class="pricing-features-item"><b>60 Day Free Trial</b></li>
							<li class="pricing-features-item">
								Click the button below to start your free trial. No Card Required.
							</li>
							<li class="pricing-features-item">
								We hope that you'll enjoy the app, but we'll automatically cancel
								your subscription if you choose not to continue at the end of your
								trial
							</li>
							<li class="pricing-features-item">
								If you have already had a free trial you will be redirected to
								Stripe, our payment provider to continue.
							</li>
						</div>

						<button @click="monthly" class="pricing-button is-featured">
							Start Free trial
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { startBilling } from "@/services/api.js";
import countryToCurrency from "country-to-currency";

//import {Browser} from "@capacitor/browser"

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "billing",
	components: {},
	async mounted() {
		await this.getRegion();
		let customer = this.$parent.$route.query.existingcustomer;
		let source = this.$parent.$route.query.source;
		let sourceid = this.$parent.$route.query.sourceid;

		this.source = source;
		this.sourceid = sourceid;

		console.debug(customer);

		if (customer) {
			this.customer = customer;
		}
	},

	data() {
		return {
			customer: null,
			source: null,
			sourceid: null,
			region: "GB",
		};
	},

	methods: {
		backend() {
			if (window.location.hostname != "join.runxact.com")
				return "http://" + window.location.hostname + ":4962";
			else return "https://3v9jxh7o04.execute-api.eu-west-2.amazonaws.com";
		},
		frontEnd() {
			return "app.runxact.com";
		},
		monthly() {
			startBilling(
				this.backend(),
				"monthly",
				this.frontEnd(),
				this.customer,
				this.source,
				this.sourceid,
				countryToCurrency[this.region]
			).then((res) => {
				if (typeof res == "string" && res != "NOREDIRECT") {
					window.location.href = res;
				} else {
					window.location.href = "https://app.runxact.com/#/login?signup=true";
				}
			});
		},

		async getRegion() {
			const loc = await new Promise((resolve, reject) => {
				fetch("https://www.cloudflare.com/cdn-cgi/trace")
					.then((res) => res.text())
					.then((t) => {
						let data = t.replace(/[\r\n]+/g, '","').replace(/=+/g, '":"');
						data = '{"' + data.slice(0, data.lastIndexOf('","')) + '"}';
						const jsondata = JSON.parse(data);
						resolve(jsondata.loc);
					})
					.catch(reject);
			});

			this.region = loc;
		},
	},
	computed: {
		pricing() {
			const currency = countryToCurrency[this.region] || "GBP";
			if (currency === "EUR") {
				return ["€8.99/month", "€84.99/year"];
			} else if (currency === "USD") {
				return ["$9.99/month", "$94.99/year"];
			} else return ["£7.99/month", "£74.99/year"];
		},
	},
};
</script>

<style scoped>
.sectionHeader {
	color: white;
	font-size: 30px;
	font-weight: bold;
	top: 0px;
}

.explain {
	font-size: 16px;
	color: #ffffff;
}

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

.background {
	padding: 0 25px 25px;
	position: relative;
	width: 100%;
}

.background::after {
	content: "";
	background: #ab95db;
	height: 350px;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

@media (min-width: 900px) {
	.background {
		padding: 0 0 25px;
	}
}

.container {
	margin: 0 auto;
	padding: 50px 0 0;
	max-width: 960px;
	width: 100%;
}

.panel {
	background-color: var(--ion-color-step-200);
	border-radius: 10px;
	padding: 15px 25px;
	position: relative;
	width: 100%;
	z-index: 10;
}

.pricing-table {
	box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09),
		0px 8px 20px 7px rgba(0, 0, 0, 0.02);
	display: flex;
	flex-direction: column;
}

@media (min-width: 900px) {
	.pricing-table {
		flex-direction: row;
	}
}

.pricing-table * {
	text-align: center;
}

.pricing-plan {
	border-bottom: 1px solid var(--ion-color-step-750);
	padding: 25px;
}

.pricing-plan:last-child {
	border-bottom: none;
}

@media (min-width: 900px) {
	.pricing-plan {
		border-bottom: none;
		border-right: 1px solid var(--ion-color-step-750);
		flex-basis: 100%;
		padding: 25px 50px;
	}

	.pricing-plan:last-child {
		border-right: none;
	}
}

.pricing-img {
	margin-bottom: 25px;
	max-width: 100%;
}

.pricing-header {
	color: #ffffff;
	font-weight: 600;
	letter-spacing: 1px;
	font-size: 34px;
	color: white;
}

.pricing-features {
	color: var(--ion-color-step-1000);
	font-weight: 600;
	letter-spacing: 1px;
	margin: 50px 0 25px;
	font-size: 22px;
}

.pricing-features-item {
	border-top: 1px solid var(--ion-color-step-750);
	font-size: 18px;
	line-height: 1.5;
	padding: 15px 0;
	color: white;
}

.pricing-features-item:first-child {
	border-top: none;
	margin-top: -15px;
}

.pricing-features-item:last-child {
	border-bottom: none;
}

.pricing-price {
	color: #ffffff;
	display: block;
	font-size: 26px;
	font-weight: 700;
}

.pricing-button {
	border-radius: 10px;
	display: inline-block;
	margin: 25px 0;
	padding: 15px 35px;
	text-decoration: none;
	transition: all 150ms ease-in-out;
	font-size: 18px;
}

.pricing-button:hover,
.pricing-button:focus {
	background-color: #f7ff00;
}

.pricing-button.is-featured {
	background-color: #f7ff00;
	color: #000000;
	font-weight: bold;
}

.topContainer {
	padding: 10px;
}</style>
