<template>
	<base-layout>
		<billing />
	</base-layout>
</template>

<script>
	import Billing from "../Userdetails/Billing.vue";

	import { getIdToken } from "@/services/firebase";

	export default {
		components: { Billing },
		methods: {},

		async mounted() {
			await getIdToken();

			console.debug(window.location.href);

			let payDone = this.$route.query.paymentDone == "true" ? true : false;
			let customer = this.$route.query.customer;
			let rebill = this.$route.query.rebill == "true" ? true : false;

			console.debug("DETAILS 5");
			console.debug(payDone, customer, rebill);

			if (payDone && customer) {
				window.location.href = "https://app.runxact.com/signin?signup=true";
			}
		},
	};
</script>

<style scoped></style>
